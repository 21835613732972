import React from "react";
const SubmitForm = ({ disabled, onChange, submitted }) => {
  return (
    <>
      <div className="row my-4">
        <div className="col-lg-6 mb-4">
          <input
            type="name"
            className="inputBox"
            placeholder="Your Name"
            onChange={(e) => onChange('name', e.target.value)} />
        </div>
        <div className="col-lg-6 mb-4">
          <input
            type="email"
            className="inputBox"
            placeholder="Your Email"
            onChange={(e) => onChange('email', e.target.value)} />
        </div>
      </div>
      <textarea rows="2" className="inputBox mb-4" placeholder="Your Message" onChange={e => onChange('message', e.target.value)} />
      <button disabled={disabled} className="btn btn-light mb-4 py-3" onClick={submitted}>
        Submit
  </button>
    </>
  );
}

export default SubmitForm;
