import React from "react"
import { SocialIcon } from "react-social-icons"
import Fade from "react-reveal/Fade"
import director from "../images/0-2.jpeg"
function TeamItem({ src, title, about, linkedin, github }) {
  return (
    <Fade bottom>
      <div className="col-lg-4 my-4 col-sm-12" style={{ textAlign: "center" }}>
        <img
          src={director}
          className="img-fluid rounded-circle"
          width="200px"
        />
        <h3 className="mt-5">{title}</h3>
        <p>{about}</p>
        <SocialIcon className="mr-4" url={github} />
        <SocialIcon className="mr-4" url={linkedin} />
      </div>
    </Fade>
  )
}

export default TeamItem
