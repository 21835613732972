import React from "react";
export function IconsAttributions({link, title, fromURL, fromName, licenseURL, licenseName, licenseText}) {
  return <div className="freepik" style={{fontSize: '8px'}}>
    Icons made by{" "}
    <a href={link} title={title}>
      {title}
    </a>{" "}
            from{" "}
    <a href={fromURL} title="Flaticon">
    {fromName}
    </a>{" "}
            is licensed by{" "}
    <a href={licenseURL} title={licenseName} target="_blank">
        {licenseText}
    </a>

  </div>;
}
