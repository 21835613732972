import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import axios from "axios"
import SubmitForm from "./SubmitForm"

export default function End() {
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [sendingState, setSendingState] = useState("pending")

  const sendEmail = () => {
    const contactMessage = { name, email, message }

    axios
      .post(
        "https://54qees21sj.execute-api.eu-west-2.amazonaws.com/dev/send-email",
        contactMessage
      )
      .then(response => {
        setSendingState(response.data)
        setMessage("")
        setEmail("")
        setName("")
      })
      .catch(function(error) {
        setSendingState("failed")
      })
  }

  const onChange = (type, value) => {
    switch (type) {
      case "name":
        setName(value)
        break
      case "email":
        setEmail(value)
        break
      case "message":
        setMessage(value)
        break
    }
  }

  const isFormEmpty = () => {
    return name === "" || email.length < 3 || message === ""
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#7E57C2", color: "white" }}
    >
      <div className="container">
        <div className="flexbox">
          <Fade>
            {
              {
                pending: (
                  <>
                    <h1 className="text-center my-5">Stay in touch</h1>
                    <SubmitForm
                      disabled={isFormEmpty()}
                      submitted={sendEmail}
                      onChange={onChange}
                    />
                  </>
                ),
                failed: (
                  <>
                    <h1
                      className="text-center my-5"
                      style={{ color: "yellow" }}
                    >
                      Sending message failed
                    </h1>
                    <SubmitForm submitted={sendEmail} onChange={onChange} />
                  </>
                ),
                sent: (
                  <>
                    <h1 className="text-center my-5">Message sent</h1>
                    <h5
                      className="text-center"
                      onClick={() => {
                        setSendingState("pending")
                      }}
                    >
                      Click here to send another one
                    </h5>
                  </>
                ),
              }[sendingState]
            }
          </Fade>
        </div>
      </div>
    </div>
  )
}
