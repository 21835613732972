import React from "react"
import { IconsAttributions } from "./IconsAttribution"

function Footer() {
  return (
    <div>
      <footer className="py-2" style={{ backgroundColor: "#5E35B1" }}>
        <div className="container">

          <IconsAttributions 
            link="https://www.freepik.com/?__hstc=57440181.5efde453cddf611e3269badc0f78e347.1563446622471.1563446622471.1563458509765.2&__hssc=57440181.1.1563458509765&__hsfp=226280184"
            title="Freepik"
            fromURL="https://www.flaticon.com/"
            fromName="flaticon.com"
            licenseURL="http://creativecommons.org/licenses/by/3.0/"
            licenseName="Creative Commons BY 3.0"
            licenseText="CC 3.0 BY"
          />
          <IconsAttributions 
            link="https://www.freepik.com/?__hstc=57440181.5efde453cddf611e3269badc0f78e347.1563446622471.1563446622471.1563458509765.2&__hssc=57440181.1.1563458509765&__hsfp=226280184"
            title="xnimrodx"
            fromURL="https://www.flaticon.com/"
            fromName="flaticon.com"
            licenseURL="https://www.freepikcompany.com/legal#nav-flaticon"
            licenseName="Flaticon License"
            licenseText="Flaticon License"
          />
          <IconsAttributions 
            link="https://icons8.com/icons/set/react"
            title="React icon"
            fromURL="https://icons8.com"
            fromName="Icons8"
            licenseURL="https://icons8.com"
            licenseName="Icons8"
            licenseText="Icons8"
          />
        </div>
      </footer>
    </div>
  )
}

export default Footer

