import React from "react"
import TeamItem from "../components/TeamItem"
import { useStaticQuery, graphql } from "gatsby"

function Team() {
  const { allTeamYaml } = useStaticQuery(graphql`
    {
      allTeamYaml {
        edges {
          node {
            name
            img
            about
            linkedin
            github
          }
        }
      }
    }
  `)
  return (
    <div
      id="team"
      className="container-fluid"
      style={{ backgroundColor: "#AFECA4" }}
    >
      <div className="container">
        <div className="flexbox" style={{ alignItems: "center" }}>
          <h1 className="text-center my-4">Meet me</h1>
          {allTeamYaml.edges.map(({ node }) => {
            console.log(node)
            return (
              <TeamItem
                key={`${node.name}`}
                title={node.name}
                src={node.img}
                about={node.about}
                linkedin={node.linkedin}
                github={node.github}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Team
